import React, {useState} from 'react';
import WallpaperObject from "./WallpaperObject";
import {Flex, Pagination, Row, Col, Button, Tooltip} from "antd";
import {WallpaperListType} from "../entity/WallpaperListEntity";

import {
    RetweetOutlined,
} from '@ant-design/icons';

interface WallpaperSectorProps {
    wallpapers: WallpaperListType;
    updatePage: (newPage: number) => void;
    updateResult?: () => void;
    showApply?: boolean;
    showDownload?: boolean;
    showSubscribe?: boolean;
    showEdit?: boolean;
    showDelete?: boolean;

    randomMode?: boolean;
}
const WallpaperSector: React.FC<WallpaperSectorProps> = ({ wallpapers, updatePage,
    updateResult,
    showApply = true, showDownload = true,
    showSubscribe = false, showEdit = false, showDelete = false,
    randomMode = false}) => {

    const [currentPage, setCurrentPage] = useState<number>(wallpapers.currentPage);
    const [totalData, setTotalData] = useState<number>(wallpapers.total);
    const handlePageChange = (newPage: number) => {
        // 更新本地的 page 状态（不过好像没有什么必要）
        setCurrentPage(newPage);

        // 调用父组件暴露的 setPage 函数，以更新全局的 page 状态并触发重新渲染
        updatePage(newPage);
    };

    return (
        <Flex align={"center"} vertical style={{flex:"1"}} className={"wallpaper-sector"}>
            <div style={{ width: "100%" }}>
                {Array.from({ length: Math.ceil(wallpapers.list.length / 3) }, (_, rowIndex) => (
                    <Row key={rowIndex} gutter={[16, 16]}>
                        {Array.from({ length: 3 }, (_, colIndex) => {
                            const index = rowIndex * 3 + colIndex;
                            return index < wallpapers.list.length ? (
                                <Col key={colIndex} span={8}>
                                    <WallpaperObject wallpaper={wallpapers.list[index]}
                                    updateResult={updateResult}
                                    showApply={showApply} showDownload={showDownload}
                                    showSubscribe={showSubscribe}
                                    showDelete={showDelete} showEdit={showEdit}/>
                                </Col>
                            ) : null;
                        })}
                    </Row>
                ))}
                {
                    wallpapers.list.length == 0 &&
                    <div style={{width:"100%" , textAlign:"center", margin:"20px"}}>
                        这个列表里暂无壁纸
                    </div>
                }
            </div>

            {
                randomMode &&

                <Tooltip title="换一批">
                    <div style={{position:"fixed", right: "50px", top:"70vh"}}>
                        <Button icon={<RetweetOutlined />}
                                type={"primary"}
                                onClick={() => {
                        if (updateResult) {
                            updateResult()
                        }}} size={"large"}></Button>
                    </div>
                </Tooltip>

            }
            {
                !randomMode &&
                <Pagination simple showSizeChanger={false} onChange={handlePageChange} defaultPageSize={12} defaultCurrent={currentPage} total={totalData} style={{marginTop:"15px",marginBottom:"20px"}}/>
            }

            {/*{*/}
            {/*    randomMode &&*/}
            {/*    <Button icon={<RetweetOutlined />} onClick={() => {*/}
            {/*        if (updateResult) {*/}
            {/*            updateResult()*/}
            {/*        }}}>换一批</Button>*/}
            {/*}*/}

        </Flex>
    );
};
export default WallpaperSector;
