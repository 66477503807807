import React, {useEffect, useRef, useState} from 'react';
import {Flex, Image, message, Modal, Radio} from 'antd';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space } from 'antd';

import {WallpaperEntity} from "../entity/WallpaperEntity";
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
    DownloadOutlined,
    DeleteRowOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    EditOutlined
} from '@ant-design/icons';
import VideoWallpaperTag from '../assets/video-wallpaper.svg';
import TextArea from "antd/es/input/TextArea";
import { postDataAdmin} from "../apis/api";


interface WallpaperObjectProps {
    wallpaper: WallpaperEntity;
    update: () => void;
    seq: number;
}

function isVideo(path: string): boolean {
    const ext = path.split('.').pop();
    return ext === 'mp4';
}
const BannerWallpaperObject: React.FC<WallpaperObjectProps> = ({ wallpaper, update, seq }) => {

    const onDownload = () => {
        fetch(wallpaper.path)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                if (isVideo(wallpaper.path)){
                    link.download = wallpaper.name+'.mp4';
                } else {
                    link.download = wallpaper.name+'.png';
                }
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                link.remove();
            });
    };

    const onApplyWallpaper = () => {
        // 预期：跳转到另一个页面（带小尾巴），或者发送id到background.js
        console.log("应用壁纸“"+wallpaper.name+"”："+wallpaper.id);
    }
    const onEditWallpaper = () => {
        // 编辑壁纸 弹出编辑面板
        showDrawer();
    }

    const { confirm } = Modal;

    const showConfirm = () => {
        confirm({
            title: '你确定要取消精选这张壁纸吗？',
            icon: <ExclamationCircleFilled />,
            cancelText: "返回",
            okText: "取消精选",
            okType: "danger",
            content: '取消精选后就不会在主页进行轮播',
            onOk() {
                onRemoveBanner();
            },
            onCancel() {
            },
        });
    };
    const onRemoveBanner = async () => {
        // 删除壁纸，确认后执行删除
        try {
            await postDataAdmin('/admin/banner/remove', {wallpaperId : wallpaper.id});
        } catch (error) {
            console.error(error);
        } finally {
            update();
        }
    }
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const [form] = Form.useForm();

    const onEditSubmit = async () => {
        try {
            // 进行表单字段的验证
            const values = await form.validateFields();
            // 如果验证通过，调用传入的 onEditSubmit 方法并传递表单数据
            // console.log(values);
            // 调用管理员-更新接口
            await postDataAdmin('/admin/wallpaper/edit',
                {
                    id : wallpaper.id,
                    name : values.name,
                    description : values.description,
                    tag : values.tag,
                    score : values.score

                    });
            await postDataAdmin('/admin/banner/edit',
                {
                    wallpaperId : wallpaper.id,
                    seq : values.seq

                });

        } catch (errorInfo) {
            console.error(errorInfo);
            message.error('请填写所有必填字段！');
        } finally {
            update();
        }
    };

    return (
        <div className="wallpaper-preview" style={{margin:"8px"}}>
            {isVideo(wallpaper.path) &&
                <Image
                    preview={{
                        destroyOnClose: true,
                        imageRender: () => (
                            <video
                                autoPlay
                                muted
                                width="80%"
                                loop
                                src={wallpaper.path}
                            />
                        ),
                        toolbarRender: (
                            _,
                            {
                            },
                        ) => (
                            <Space size={20} style={{fontSize:"40px"}} className="toolbar-wrapper">
                                <DownloadOutlined onClick={onDownload} />
                                <PlusCircleOutlined onClick={onApplyWallpaper} />
                            </Space>
                        ),
                    }}
                    src={wallpaper.previewPath.replace("{width}","600").replace("{height}","400")}
                    className={"image-preview"}
                    style={{borderRadius:"8px",
                        width: '31vw',
                        height: '20vw',
                        maxWidth : '310px',
                        maxHeight : '200px'
                    }}
                    alt={wallpaper.name}
                />
            }
            {!isVideo(wallpaper.path) &&
                <Image

                    className={"image-preview"}
                    style={{borderRadius:"8px",
                        width: '31vw',
                        height: '20vw',
                        maxWidth : '310px',
                        maxHeight : '200px'
                    }}
                    alt={wallpaper.name}
                    src={wallpaper.previewPath.replace("{width}","600").replace("{height}","400")}
                    preview={{
                        toolbarRender: (
                        ) => (
                            <Space size={20} style={{fontSize:"40px"}} className="toolbar-wrapper">
                                <DownloadOutlined onClick={onDownload} />
                                <PlusCircleOutlined onClick={onApplyWallpaper} />
                            </Space>
                        ),
                    }}
                />
            }

            <Flex vertical={false}
                  style={{position:"absolute",
                      top:"5px",
                      width:"100%",
                      flexDirection:"row-reverse"}}
                  className={"wallpaperButtonDiv"}
            >
                <button
                    style={{
                        background: "rgba(0, 0, 0, 0.5)",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                    className={"wallpaperOpButton"}
                    onClick={onEditWallpaper}
                >

                    <EditOutlined />
                    编辑
                </button>
                {wallpaper.status!==-2 && (<button
                    style={{
                        background: "rgba(0, 0, 0, 0.5)",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                    className={"wallpaperOpButton wallpaperOpButtonAlert"}
                    onClick={showConfirm}
                >
                    <DeleteRowOutlined />
                    移出精选
                </button>)}

            </Flex>
            <div className={"wallpaperStatusBanner"}
                 style={{
                     background: "rgba(0, 0, 0, 0.5)",
                     color: "#fff",
                     position:"absolute",
                     width:"31vw",
                     maxWidth:"310px",
                     bottom:"0",
                     textAlign: "center",
                     borderRadius:"0 0 6px 6px"
                 }}>
                状态：{
                wallpaper.status === -2 ? <span style={{color:"#f2b3b3"}}>删除</span> :
                    (wallpaper.status === -1 ? <span style={{color:"#e88c0b"}}>不通过</span> :
                        (wallpaper.status === 0 ? <span style={{color:"#e9cb7f"}}>审核中</span>:
                            (wallpaper.status === 1 ? <span style={{color:"#4fe442"}}>通过</span> : "未知状态")))
            }
            </div>
            <div className={"wallpaperNameBanner"}
                 style={{
                     background: "rgba(0, 0, 0, 0.5)",
                     color: "#fff",
                     position:"absolute",
                     width:"31vw",
                     maxWidth:"310px",
                     bottom:"0",
                     textAlign: "center",
                     borderRadius:"0 0 6px 6px"
                 }}>
                名称：{wallpaper.name}, 排序号：{seq}
                <br></br>
                标签：{wallpaper.tag}
                <br></br>
                {wallpaper.description.length > 40
                    ? wallpaper.description.substring(0, 40) + '...'
                    : wallpaper.description}
                <br></br>
                状态：{
                wallpaper.status === -2 ? <span style={{color:"#f2b3b3"}}>删除</span> :
                    (wallpaper.status === -1 ? <span style={{color:"#e88c0b"}}>不通过</span> :
                        (wallpaper.status === 0 ? <span style={{color:"#e9cb7f"}}>审核中</span>:
                            (wallpaper.status === 1 ? <span style={{color:"#4fe442"}}>通过</span> : "未知状态")))
            }
            </div>

            {isVideo(wallpaper.path) &&
                <img
                    src={VideoWallpaperTag}
                    alt="动态视频"
                    style={{
                        position:"absolute",
                        top: "0",
                        left: "-1px",
                        maxWidth:"80px",
                        maxHeight:"60px",
                        width:"4vw",
                        height:"3vw",
                        zIndex:"1",
                    }}
                />
            }

            <Drawer title="壁纸编辑" onClose={onClose} open={open}
                    extra={
                        <Space>
                            <Button onClick={onClose}>取消</Button>
                            <Button onClick={onEditSubmit} type="primary">
                                保存
                            </Button>
                        </Space>
                    }>
                <Form layout="vertical" form={form}
                      initialValues={{
                          name: wallpaper.name,
                          tag: wallpaper.tag,
                          description: wallpaper.description,
                          score: wallpaper.officialScore,
                          seq: seq
                      }}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="壁纸名称"
                                rules={[{ required: true, message: '请输入壁纸的名称！' }]}
                            >
                                <Input placeholder="壁纸的标题"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="tag"
                                label="标签"
                                rules={[{ required: true, message: '请输入壁纸的标签！' }]}
                            >
                                <Input placeholder="壁纸的标签，逗号分割" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="描述"
                                rules={[{ required: true, message: '请输入壁纸的描述！' }]}
                            >
                                <TextArea placeholder="壁纸详细描述" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="score"
                                label="官方分数"
                            >
                                <Input placeholder="官方的评分，和互动分数求和后排序" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="seq"
                                label="精选壁纸排序号"
                            >
                                <Input placeholder="精选壁纸按照此号码升序排列" />
                            </Form.Item>
                        </Col>
                    </Row>


                    <div style={{overflowWrap:"anywhere"}}>
                        壁纸路径：
                        <br></br>
                        {wallpaper.path}
                        <br></br>
                        其他信息:
                        <br></br>
                        总分数：{wallpaper.score}
                        <br></br>
                        互动分数：{wallpaper.interactScore}
                        <br></br>
                        喜欢：{wallpaper.likes}，收藏：{wallpaper.subscribes}，分享：{wallpaper.shares}
                        <br></br>
                        <span style={{color:"#FF2222"}}>不喜欢：</span>{wallpaper.dislikes}
                    </div>
                </Form>
            </Drawer>
        </div>
    )


}

export default BannerWallpaperObject;
