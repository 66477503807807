import React, {useState, useEffect} from 'react';
import {
    Avatar,
    Button, Col,
    Drawer,
    Dropdown,
    Flex,
    Form, Input,
    message,
    Modal,
    Progress, Row,
    Space, Upload,
    UploadFile, UploadProps
} from "antd";
import {UserOutlined, SmileOutlined, UploadOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {baseUrl, installUrl, MAX_FILE_SIZE} from "../apis/api";
import {WallpaperEntity} from "../entity/WallpaperEntity";
import {RcFile} from "antd/es/upload";
import TextArea from "antd/es/input/TextArea";
import {showAdmin} from "../admin/AppForAdmin";

export const getUserToken = (): string | null => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
        return rootElement.getAttribute('data-token');
    }
    return null;
};

export const getIsAdmin = (): boolean => {
    // 读取是否展现后台
    return showAdmin;
}

// 跳转到商店页面
const jumpInstall = () => {
    const userToken = getUserToken();
    if (userToken == null) {
        window.open(installUrl, '_blank');
    }
}
//跳转到新标签页（安装插件后）
const jumpNewTab = () => {
    window.open("", '_blank');
}


interface UserinfoProps {
    showUpload?:boolean

    updateResult?: () => void;
}

const UserInfo: React.FC<UserinfoProps> = ({showUpload=false, updateResult}) => {


    const navigate = useNavigate();

    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [showHomeButton, setShowHomeButton] = useState<boolean>(false);
    const [token, setToken] = useState<string|null>(null);


    const [open, setOpen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentFile, setCurrentFile] = useState<string | null>(null);

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    // 跳转到【我的收藏】页面
    const jumpSubscribe = () => {
        navigate('/subscribe/');
    }
    // 跳转到【上传壁纸】页面
    const jumpUpload = () => {
        navigate('/upload/');
    }
    // 跳转到【桌面工具】页面
    const jumpDesktop = () => {
        navigate('/desktop/');
    }
    // 跳转到【博客】页面（测试）
    const jumpTestBlog = () => {
        navigate('/blog/665eb29256d17e28093e8a82');
    }
    const getMenuItems = (token:string|null) => {
        const baseItems = [
        ];

        if (token != null && token != "NotLogin") {
            baseItems.push({
                key: '1',
                label: (
                    <a onClick={jumpSubscribe}>
                        我的收藏
                    </a>
                ),
            });
            baseItems.push({
                key: '2',
                label: (
                    <a onClick={jumpUpload}>
                        上传壁纸
                    </a>
                ),
            });
        } else if (token == "NotLogin") {
            baseItems.push({

                key: '99',
                label: (
                    <a onClick={jumpNewTab}>
                        插件端登录
                    </a>
                ),
            });
        } else {
            baseItems.push({

                key: '88',
                label: (
                    <a onClick={jumpInstall}>
                        安装插件
                    </a>
                ),
            });
        }

        // baseItems.push({
        //     key: '99',
        //     label: (
        //         <a onClick={jumpDesktop}>
        //             动态壁纸桌面工具
        //         </a>
        //     ),
        // });

        return baseItems;
    };


    useEffect(() => {
        const currentToken = getUserToken();
        setToken(currentToken);
        // if (currentToken !== null) {
        //     console.log('User token:', currentToken);
        // } else {
        //     console.log('No token found');
        // }

        // 鉴定是否管理员
        if (getIsAdmin()) {
            // console.log("管理员登录");
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }, []);

    // 跳转到管理员页面
    const jumpAdmin = () => {
        navigate('/admin/');
    };

    // 跳转到主页面
    const jumpHome = () => {
        navigate('/');
    };

    // 通过路径检测是否需要展示“返回主页”按钮
    useEffect(() => {
        const path = window.location.pathname;
        setShowHomeButton(path !== '/');
    }, []);



    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const [form] = Form.useForm();

    // 新版上传壁纸（带有本地进度条）
    const onCreateSubmit = async () => {
        try {
            const values = await form.validateFields();
            const fileList = values.file.fileList;

            setUploading(true);
            setProgress(0);

            for (const file of fileList) {
                if (file.originFileObj.size >= MAX_FILE_SIZE) {
                    message.error(`文件 "${file.name}" 超过20MB，请压缩或选择更小的文件。`);
                    setUploading(false);
                    setProgress(0);
                    return; // 停止上传过程
                }
                await uploadFile(values, file.originFileObj as RcFile);
            }

            message.success('壁纸上传完成');
            form.resetFields();
            setFileList([]);
            setUploading(false);
            setProgress(100);
            setCurrentFile(null);
        } catch (errorInfo) {
            console.error(errorInfo);
            message.error('请填写所有必填字段！');
            setUploading(false);
        } finally {
            setOpen(false);
            if (updateResult != undefined) {
                updateResult();
            }
        }
    };

    const handleFileChange: UploadProps['onChange'] = (info) => {
        const newFileList = info.fileList.map(file => {
            if (file.response) {
                // 修改 file 状态为 done
                file.status = 'done';
            }
            return file;
        });
        setFileList(newFileList);
    };

    const customRequest = ({ file, onSuccess }: any) => {
        // 使用 setTimeout 模拟文件上传并立即调用 onSuccess
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const uploadFile = (values: any, file: RcFile) => {
        // 由于这个请求需要进度条，所以没有写在api.ts里面
        const authToken = getUserToken();
        if (authToken == null){
            message.warning('请在插件端登录后操作');
            return
        }

        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('name', values.name);
            formData.append('description', values.description);
            formData.append('tag', values.tag);

            const xhr = new XMLHttpRequest();
            xhr.open('POST', baseUrl+'wallpaper/create', true);

            // 设置 Authorization 请求头
            xhr.setRequestHeader('Authorization', `${authToken}`);

            xhr.upload.onprogress = (event: ProgressEvent) => {
                if (event.lengthComputable) {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    setProgress(percent);
                    setCurrentFile(file.name);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(xhr.response);
                }
            };

            xhr.onerror = () => {
                reject(xhr.response);
            };

            xhr.send(formData);
        });
    };

    return (
        <Flex align={"center"} justify={"flex-end"} style={{maxWidth:"300px", minWidth:"20vw", marginRight:"30px"}}>
            <div style={{display:"none"}}>占位符</div>
            {/* <Button
                style={{marginRight:"10%"}}
                onClick={jumpTestBlog}>测试博客
            </Button> */}
            {(isAdmin && !showUpload &&
                <Button
                        style={{marginRight:"10%"}}
                        onClick={jumpAdmin}>前往后台
                </Button>
            )}
            {(showHomeButton &&
                <Button
                        style={{marginRight:"10%"}}
                        onClick={jumpHome}>返回主页
                </Button>
                )
            }
            {
                showUpload &&
                <Button type={"primary"}
                        style={{marginRight:"10%"}}
                        onClick={showDrawer}>上传
                </Button>
            }

            {/*{*/}
            {/*    token != null && token != "NotLogin" &&*/}
            {/*    <Dropdown menu={{ items: getMenuItems(token )  }} placement="bottom" arrow>*/}
            {/*        <Avatar size="large" icon={<SmileOutlined />} style={{cursor:"pointer"}}/>*/}
            {/*    </Dropdown>*/}
            {/*}*/}
            {/*{*/}
            {/*    (token == null || token == "NotLogin") &&*/}
            {/*    <Dropdown menu={{ items: getMenuItems(token ) }} placement="bottom" arrow>*/}
            {/*        <Avatar size="large" icon={<UserOutlined />} style={{cursor:"pointer"}}/>*/}
            {/*    </Dropdown>*/}
            {/*}*/}


            <Drawer title="壁纸编辑" onClose={onClose} open={open}
                    extra={
                        <Space>
                            <Button onClick={onClose}>取消</Button>
                            <Button onClick={onCreateSubmit} type="primary" disabled={uploading}>
                                保存
                            </Button>
                        </Space>
                    }>
                <Modal
                    visible={uploading}
                    footer={null}
                    closable={false}
                    centered
                >
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <p>{currentFile ? `正在上传: ${currentFile}` : '准备上传...' }</p>
                        <Progress percent={progress} status="active" />
                        <span style={{color:"#777"}}>
                            提示：上传完成后仍需等待文件从服务器存到云端...
                        </span>
                    </div>
                </Modal>

                <Form layout="vertical" form={form}>
                        <span style={{color:"#777"}}>
                            提示：当前仅允许上传大小在20MB以内的壁纸文件
                        </span>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="file" label="上传壁纸">
                                <Upload
                                    name="wallpaper"
                                    accept="image/jpeg,image/jpg,image/png,image/bmp,image/webp,image/jfif,video/mp4"
                                    fileList={fileList}
                                    onChange={handleFileChange}
                                    customRequest={customRequest}
                                    multiple={true}
                                >
                                    <Button>
                                        <UploadOutlined /> 点击上传
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="壁纸名称"
                                rules={[{ required: true, message: '请输入壁纸的名称！' }]}
                                initialValue={"壁纸"}
                            >
                                <Input placeholder="壁纸的标题"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="tag"
                                label="标签"
                                rules={[{ required: true, message: '请输入壁纸的标签！' }]}
                                initialValue={"自定义，上传"}
                            >
                                <Input placeholder="壁纸的标签，逗号分割" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="描述"
                                rules={[{ required: true, message: '请输入壁纸的描述！' }]}
                                initialValue={"用户上传的壁纸"}
                            >
                                <TextArea placeholder="壁纸详细描述" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

        </Flex>
    );
};

export default UserInfo;
