import React, {useEffect, useState} from 'react';
import { useMedia } from 'react-use';
import SearchTagBar from "./SearchTagBar";
import Logo from '../assets/logo.svg';
import LogoSimple from '../assets/logoSimple.svg';
import UserInfo from "./UserInfo";
import {useNavigate} from "react-router-dom";

interface NavProps {
    updateSearch?: (searchTag: string) => void;
    updateResult?: () => void;
    showSearch?: boolean;
    fullIcon?: string;
    logoJumpHome?: boolean;
    showUpload?: boolean;

    updateSearchOrder?: (searchOrder: string) => void;
    initialSearchTag?: string;
    initialSearchOrder?: string;
}
const Navbar: React.FC<NavProps> = ({updateSearch, updateResult,
                                        showSearch = false, fullIcon = Logo,
                                    logoJumpHome = true, showUpload = false,
                                    updateSearchOrder,
                                    initialSearchTag, initialSearchOrder}) => {
    const shortScreen = useMedia( '(max-width: 1000px)' );
    const [iconToHome, setIconToHome] = useState<boolean>(false);

    // 通过路径检测是否需要展示“返回主页”按钮
    useEffect(() => {
        const path = window.location.pathname;
        setIconToHome(path !== '/');
    }, []);

    const fullLogo = fullIcon? fullIcon : Logo;
    const logoSrc = shortScreen ? LogoSimple : fullLogo;
    const navigate = useNavigate();

    const handleBackHome = () => {
        navigate('/');
    }

    return (
        <header className="navbar">
            <div style={{marginLeft:"15px",maxWidth:"300px"}}>
                {
                    iconToHome &&
                    <img className="logoIcon" src={logoSrc} alt="logo"
                         style={{cursor:"pointer"}} onClick={handleBackHome}></img>
                }
                {
                    !iconToHome &&
                    <img className="logoIcon" src={logoSrc} alt="logo"></img>
                }
            </div>
            {
                showSearch && updateSearch != null &&
                <SearchTagBar updateSearch={updateSearch} updateSearchOrder={updateSearchOrder}
                    initialSearchTag={initialSearchTag} initialSearchOrder={initialSearchOrder}></SearchTagBar>
            }
            {
                (!showSearch || updateSearch == null ) &&
                <div style={{width:"100%"}}>

                </div>
            }

            <UserInfo showUpload={showUpload} updateResult={updateResult}></UserInfo>
        </header>
    );
};

export default Navbar;
