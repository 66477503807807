import React, {useState, useEffect, useRef} from 'react';
import Search from "antd/es/input/Search";
import {Flex, Select} from "antd";
import {fetchData} from "../apis/api";
import styled from "styled-components";
import {
    TagOutlined,
    PictureOutlined,
    PlaySquareOutlined
} from '@ant-design/icons';
import {defaultSearchOrder} from "../pages/home/page";

const TagButton = styled.button`
  display: inline-block;
  
  background-color: #bfa;
  color: black;
  font-size: 16px;
  min-width: 64px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid snow;
  margin: 5px;
  opacity: 0.7;
  transition: color 0.3s ease, opacity 0.3s ease, border-color 0.3s;
  &:hover {
    color: red; 
    opacity: 1;
    border: 1px solid black;
  }
`;


const TipPopup = styled.div`
  position: absolute;
  padding: 10px;
  top: 35px;
  left: 0;
  z-index: 10;
  width: 95%;
  border-radius: 8px;
  border: 1px solid black;
  background-color: snow; // 背景颜色
  
  display: flex;
  text-align: center;
  flex-wrap: wrap;
`;


type TagListType = Tag[]
type Tag = {
    "name":string,
    "type1":string,
    "type2":string,
    "tagColor":string,
    "tagIcon":String
}


interface NavProps {
    updateSearch: (searchTag: string) => void;
    updateSearchOrder?: (searchOrder: string) => void;
    initialSearchTag?: string;
    initialSearchOrder?: string;
}
const SearchTagBar: React.FC<NavProps> = ({updateSearch, updateSearchOrder,
                                          initialSearchTag, initialSearchOrder}) => {
    const [searchText, setSearchText] = useState('');
    const [searchOrder, setSearchOrder] = useState(defaultSearchOrder);
    const [showTip, setShowTip] = useState(false);

    const [tagList, setTagList] = useState<TagListType | null>(null);

    const tipPopupRef = useRef<HTMLDivElement | null>(null);
    const clickedElementRef = useRef<EventTarget | null>(null);
    const getTagList = async () => {
        try {
            const tagRequest = await fetchData('/wallpaper/search/tag');
            setTagList(tagRequest);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getTagList();

        if (initialSearchTag){
            setSearchText(initialSearchTag);
        }
        if (initialSearchOrder) {
            setSearchOrder(initialSearchOrder);
        }
    }, []);


    const handleMouseDown = (event: MouseEvent) => {
        clickedElementRef.current = event.target;
    };

    // 处理输入框的聚焦和失焦
    const handleFocus = () => {
        setShowTip(true);
    };
    const handleBlur = () => {
        const clickedElement = clickedElementRef.current;
        if (
            clickedElement instanceof HTMLButtonElement ||
            clickedElement instanceof SVGElement ||
            clickedElement instanceof SVGPathElement
        ) {
            // 如果目标元素是一个 按钮 ，不直接收起弹窗
            return;
        } else {
            setShowTip(false);
        }
    };

    useEffect(() => {
        // 在组件挂载时监听 document 的 mouse down 事件
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            // 在组件卸载时移除事件监听器
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    const handleAddTag = (tagName: string) => {
        const newText = searchText === "" ? "#"+tagName+"#" : `${searchText}，#${tagName}#`;
        setSearchText(newText);
        updateSearch(newText);

        setShowTip(false);
    };
    const handleSearch = (searchTag:string) => {
        updateSearch(searchTag);
    };
    const getIconForTag = (tagName: string): JSX.Element => {
        switch (tagName) {
            case '静态':
                return <PictureOutlined/>;
            case '动态':
                return <PlaySquareOutlined/>;
            // 添加更多 cases 用于其他标签
            default:
                return <TagOutlined/>;
        }
    };
    const getBackgroundColorForTag = (tagName: string): string => {
        switch (tagName) {
            case '静态':
                return "#F5DEB3";
            case '动态':
                return "#98FB98";
            // 添加更多 cases 用于其他标签
            default:
                return "#ADD8E6";
        }
    };


    let tagButtons;
    if (tagList!=null){
        tagButtons =
             tagList.map((tag) => (
                <TagButton key={tag.name}
                           style={{backgroundColor: tag.tagColor? tag.tagColor : getBackgroundColorForTag(tag.name) }}
                           onClick={() => handleAddTag(tag.name)}>
                    <div style={{display:"inline-block"}}
                        // onClick={(e)=>{console.log("点击事件触发"); e.stopPropagation(); handleAddTag(tag.name)}}
                    >{tag.tagIcon ? tag.tagIcon : getIconForTag(tag.name)}</div>
                    {tag.name}
                </TagButton>
            ));
    } else {
        tagButtons = ""
    }

    const handleSearchOrderChange = (searchOrder:string) => {
        if (updateSearchOrder){
            setSearchOrder(searchOrder);
            updateSearchOrder(searchOrder);
        }
    }

    return (
        <div style={{width:"100%"}}>
        {
            updateSearchOrder &&
            <Flex vertical={false} align={"center"} justify={"center"} style={{width:"100%"}}>
                <Flex vertical align={"center"} style={{width:"70%"}}>
                    <div style={{position:"relative", width:"100%"}}>
                        <Search
                            placeholder="输入关键词或选择标签筛选壁纸"
                            allowClear
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onSearch={handleSearch}
                            style={{ width: '100%' }}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        {showTip && (
                            <TipPopup ref={tipPopupRef}>
                                {tagButtons}

                            </TipPopup>
                        )}
                    </div>
                </Flex>

                <Select
                    defaultValue={defaultSearchOrder}
                    style={{ width: 120 }}
                    onChange={handleSearchOrderChange}
                    options={[
                        { value: "创建时间", label: '最新' },
                        { value: "热度", label: '最热门' },
                        { value: "随机", label: '随机' }
                    ]}
                    value={searchOrder}
                />

            </Flex>
        }

        {
            !updateSearchOrder &&
            <Flex vertical={false} align={"center"} justify={"center"} style={{width:"100%"}}>
                <div style={{position:"relative", width:"80%"}}>
                    <Search
                        placeholder="搜索壁纸"
                        allowClear
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onSearch={handleSearch}
                        style={{ width: '100%' }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    {showTip && (
                        <TipPopup ref={tipPopupRef}>
                            {tagButtons}

                        </TipPopup>
                    )}
                </div>

            </Flex>
        }

        </div>
    );
};

export default SearchTagBar;
