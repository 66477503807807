import React, {useEffect, useState} from 'react';
import WallpaperSector from '../../components/WallpaperSector';
import '../../App.css';
import {fetchData} from "../../apis/api";
import {Button, Flex, message, Modal, Spin} from "antd";
import {useParams} from "react-router-dom";
import Navbar from "../../components/Navbar";
import {
    DeploymentUnitOutlined, EditOutlined,
} from "@ant-design/icons";
import {getUserToken} from "../../components/UserInfo";
import Logo from '../../assets/logo.svg';
import {WallpaperListType} from "../../entity/WallpaperListEntity";
import EditDrawer from "./edit";

export type BlogType = {
    "title": string,
    "content": string,
    "avatarSrc": string,
    "isPublic": number
}


function isVideo(path: string): boolean {
    const ext = path.split('.').pop();
    return ext === 'mp4';
}
function Page() {

    const { userId } = useParams<{ userId: string }>();

    const [blogResponse, setBlog] = useState<BlogType|null>(null);
    const [wallpaper, setAllWallpaper] = useState<WallpaperListType | null>(null);

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const [page, setPage] = useState<number>(1);
    const getAllWallpaper = async (userId:string|undefined, page:number) =>{
        if (userId === undefined){
            return;
        }
        try {
            setLoading(true);
            const wallpaperRequest = await fetchData('/wallpaper/search/user-upload?userId=' + encodeURIComponent(userId) + '&page='+page+'&offset=12');
            setAllWallpaper(wallpaperRequest);
        } catch (error) {
            setError("获取壁纸数据失败");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        // wallpaperId 改变的时候，更新wallpaper数据
        getBlog(userId);
        getAllWallpaper(userId,page);
    }, [userId, page]);


    const getBlog = async (userId:string|undefined) => {
        try {
            const blogRequest = await fetchData('/blog/view-by-user?userId='+userId);
            setBlog(blogRequest);
        } catch (error) {
            setError("获取博客数据失败");
        }
    };


    // 暴露给 WallpaperSector 组件调用的 setPage 函数
    const updatePage = (newPage: number) => {
        setPage(newPage);
    };

    const [shareModalVisible, setShareModalVisible] = useState(false);
    const [shareContent, setShareContent] = useState("");
    // 分享
    const handleShare = async () => {
        try {
            const userToken = getUserToken();
            // if (userToken == null || userToken == "NotLogin") {
            //     message.warning('请在插件端登录后操作');
            // }
            if (wallpaper == null) {
                return
            }
            // const shareResponse = await postDataUser("/wallpaper/interact/share2", {wallpaperId: wallpaper.id}, userToken);
            // setShareContent(shareResponse);
            setShareModalVisible(true);

        } catch (error) {
            setError("壁纸互动失败")
        }
    }
    async function handleShareCopy(): Promise<void> {
        try {
            if (navigator.clipboard && window.isSecureContext) {
                // 如果浏览器支持 Clipboard API 且在安全上下文中
                await navigator.clipboard.writeText(shareContent);
            } else {
                // 备用方法
                await new Promise<void>((resolve, reject) => {
                    let textArea = document.createElement("textarea");
                    textArea.value = shareContent;
                    textArea.style.position = "fixed";
                    textArea.style.left = "-999999px";
                    textArea.style.top = "-999999px";
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();

                    if (document.execCommand('copy')) {
                        resolve();
                    } else {
                        reject(new Error('Failed to copy'));
                    }
                    textArea.remove();
                });
            }
            message.success('内容已复制到剪切板');
        } catch (error) {
            console.error('复制失败:', error);
            message.error('复制失败');
        } finally {
            setShareModalVisible(false);
        }
    }
    const handleShareCancel = () => {
        setShareModalVisible(false);
    }

    const [blogEditOpen, setBlogEditOpen] = useState(false);

    const showDrawer = () => {
        setBlogEditOpen(true);
    };

    const onClose = () => {
        setBlogEditOpen(false);
    };

    if (error) {
        return <div className={"App"}>
            <Navbar updateSearch={undefined} showSearch={false} fullIcon={Logo}/>
            <div className={"loading"}>

                <div>发生错误：{error}</div>
                <div>建议检查网络环境，刷新重试~</div>
                <div>
                    <Button onClick={() => window.location.reload()}
                            style={{margin:"10px"}}>重新加载</Button>
                </div>

            </div>
        </div>;
    }

    if (!wallpaper || !blogResponse || loading){
        return <div className="App">
            <Navbar updateSearch={undefined} showSearch={false} fullIcon={Logo}/>
            <div className="loading">
                <Spin size="large">
                </Spin>
            </div>
        </div>
    }
    return (
        <div className="App">
            <Navbar updateSearch={undefined} showSearch={false} fullIcon={Logo}/>

            <Modal
                open={shareModalVisible}
                title="分享链接"
                onOk={handleShareCopy}
                onCancel={handleShareCancel}
                footer={[
                    <Button key="back" onClick={handleShareCancel}>
                        返回
                    </Button>,
                    <Button type="primary" onClick={handleShareCopy}>
                        复制
                    </Button>,
                ]}
            >
                <p>复制下方内容将本壁纸分享给你的朋友：</p>
                <p>{shareContent}</p>
            </Modal>

            <EditDrawer open={blogEditOpen} onClose={onClose}></EditDrawer>

            <Flex align={"center"} vertical={true}>
                {blogResponse.avatarSrc &&
                    <img src={blogResponse.avatarSrc} alt={"博文配图"}
                        style={{maxWidth:"80%", marginLeft:"5%", marginRight:"5%",
                            marginBottom:"10px", marginTop:"10px",
                            borderRadius:"8px"}}></img>
                }
                <div style={{fontSize:"32px"}}>
                    {blogResponse.title} <EditOutlined onClick={showDrawer}/>
                </div>
                <div style={{color:"#999"}}>
                    <span style={{color:"#000"}}>璃月月</span> 发表于 2024年9月5日 16:49
                </div>
                <div style={{maxWidth:"80%", marginLeft:"5%", marginRight:"5%",
                    marginBottom:"10px", marginTop:"10px"}}
                     dangerouslySetInnerHTML={{ __html: blogResponse.content }}>

                </div>
            </Flex>

            <div style={{maxWidth:"1000px",
                margin: "auto auto"}}>

            <span
                style={{
                    fontSize:"28px",
                    textAlign:"left",
                    display:"inline-block",
                    width:"100%",
                    marginTop:"10px",
                    marginBottom:"10px"
                }}>
                <DeploymentUnitOutlined /> 博主的全部壁纸
            </span>

            </div>
            <div className="content">
                <WallpaperSector wallpapers={wallpaper} updatePage={updatePage} />
            </div>
        </div>
    );
}

export default Page;
