import React, {useState, useEffect, useRef} from 'react';
import Search from "antd/es/input/Search";
import {Flex, Select} from "antd";
import {fetchData} from "../apis/api";
import styled from "styled-components";
import {
    TagOutlined,
    PictureOutlined,
    PlaySquareOutlined
} from '@ant-design/icons';

const TagButton = styled.button`
  display: inline-block;
  
  background-color: #bfa;
  color: black;
  font-size: 16px;
  min-width: 64px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid snow;
  margin: 5px;
  opacity: 0.7;
  transition: color 0.3s ease, opacity 0.3s ease, border-color 0.3s;
  &:hover {
    color: red; 
    opacity: 1;
    border: 1px solid black;
  }
`;


const TipPopup = styled.div`
  position: absolute;
  padding: 10px;
  top: 35px;
  left: 0;
  z-index: 10;
  width: 95%;
  border-radius: 8px;
  border: 1px solid black;
  background-color: snow; // 背景颜色
  
  display: flex;
  text-align: center;
  flex-wrap: wrap;
`;


type TagListType = Tag[]
type Tag = {
    "name":string,
    "type1":string,
    "type2":string,
    "tagColor":string,
    "tagIcon":String
}


interface NavProps {
    updateSearch: (searchTag: string, status: number|null) => void;
}
const SearchTagBar: React.FC<NavProps> = ({updateSearch}) => {
    const [searchText, setSearchText] = useState('');
    const [status, setStatus] = useState<number | null>(null);
    const [showTip, setShowTip] = useState(false);

    const [tagList, setTagList] = useState<TagListType | null>(null);
    const [error, setError] = useState<string | null>(null);

    const tipPopupRef = useRef<HTMLDivElement | null>(null);
    const clickedElementRef = useRef<EventTarget | null>(null);
    const getTagList = async () => {
        try {
            const tagRequest = await fetchData('/wallpaper/search/tag');
            setTagList(tagRequest);
        } catch (error) {
            setError("获取推荐标签失败");
            console.error(error);
        }
    };
    useEffect(() => {
        getTagList();
    }, []);


    const handleMouseDown = (event: MouseEvent) => {
        clickedElementRef.current = event.target;
    };

    // 处理输入框的聚焦和失焦
    const handleFocus = () => {
        setShowTip(true);
    };
    const handleBlur = () => {
        const clickedElement = clickedElementRef.current;
        if (
            clickedElement instanceof HTMLButtonElement ||
            clickedElement instanceof SVGElement ||
            clickedElement instanceof SVGPathElement
        ) {
            // 如果目标元素是一个 按钮 ，不直接收起弹窗
            return;
        } else {
            setShowTip(false);
        }
    };

    useEffect(() => {
        // 在组件挂载时监听 document 的 mouse down 事件
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            // 在组件卸载时移除事件监听器
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    useEffect(() => {
        updateSearch(searchText, status);
    }, [status]);


    const handleAddTag = (tagName: string) => {
        setSearchText(prevText => {
            const newText = prevText === "" ? "#"+tagName+"#" : `${prevText}，#${tagName}#`;
            setSearchText(newText);
            updateSearch(newText, status);
            return newText;
        });
        setShowTip(false);
    };


    const handleStatusChange = (status: number) => {
        setStatus(status);
    }
    const handleSearch = (searchTag:string) => {
        setSearchText(searchTag);
        updateSearch(searchText, status);
    };
    const getIconForTag = (tagName: string): JSX.Element => {
        switch (tagName) {
            case '静态':
                return <PictureOutlined />;
            case '动态':
                return <PlaySquareOutlined />;
            // 添加更多 cases 用于其他标签
            default:
                return <TagOutlined />;
        }
    };
    const getBackgroundColorForTag = (tagName: string): string => {
        switch (tagName) {
            case '静态':
                return "#F5DEB3";
            case '动态':
                return "#98FB98";
            // 添加更多 cases 用于其他标签
            default:
                return "#ADD8E6";
        }
    };


    let tagButtons;
    if (tagList!=null){
        tagButtons =
             tagList.map((tag) => (
                <TagButton key={tag.name}
                           style={{backgroundColor: tag.tagColor? tag.tagColor : getBackgroundColorForTag(tag.name) }}
                           onClick={() => handleAddTag(tag.name)}>
                    {tag.tagIcon ? tag.tagIcon : getIconForTag(tag.name)}
                    {tag.name}
                </TagButton>
            ));
    } else {
        tagButtons = ""
    }
    return (
        <Flex vertical={false} align={"center"} justify={"center"} style={{width:"100%"}}>
            <Flex vertical align={"center"} style={{width:"70%"}}>
                <div style={{position:"relative", width:"100%"}}>
                    <Search
                        placeholder="在“所有壁纸”中搜索"
                        allowClear
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onSearch={handleSearch}
                        style={{ width: '100%' }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    {showTip && (
                        <TipPopup ref={tipPopupRef}>
                            {tagButtons}

                        </TipPopup>
                    )}
                </div>
            </Flex>

            <Select
                defaultValue={999}
                style={{ width: 120 }}
                onChange={handleStatusChange}
                options={[
                    { value: 999, label: '全部状态' },
                    { value: 1, label: '已通过' },
                    { value: '0', label: '审核中' },
                    { value: -1, label: '未通过'},
                    { value: -2, label: '已删除'},
                ]}
            />

        </Flex>

    );
};

export default SearchTagBar;
