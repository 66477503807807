import React, {useState} from 'react';
import '../../App.css';
import {Button, Flex, Modal, Progress} from "antd";
import Navbar from "../../components/Navbar";
import {
    PictureOutlined, WindowsOutlined, DownloadOutlined, FolderOutlined,
} from "@ant-design/icons";
import Logo from '../../assets/logo.svg';


function isVideo(path: string): boolean {
    const ext = path.split('.').pop();
    return ext === 'mp4';
}
function Page() {

    const desktop_download_path = "https://img.picknewtab.com/wallpaperTab/file/动态壁纸桌面工具.rar"
    const desktop_screenshot_path = "https://img.picknewtab.com/wallpaperTab/file/helper.png"


    const [progress, setProgress] = useState("0");
    const [downloadModalVisible, setDownloadModalVisible] = useState(false);
    const [downloadedMB, setDownloadedMB] = useState(0);
    const [totalMB, setTotalMB] = useState(0);

    const onDownload = async () => {
        setDownloadModalVisible(true);

        const response = await fetch(desktop_download_path);
        const contentLength = response.headers.get('content-length');
        const total = parseInt(contentLength || '0', 10);
        setTotalMB(total / (1024 * 1024)); // 将总字节数转换为MB

        if (!response.body) {
            throw new Error('ReadableStream is not supported in this browser.');
        }

        const reader = response.body.getReader();
        let receivedLength = 0;
        const chunks: Uint8Array[] = [];

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            if (value) {
                chunks.push(value);
                receivedLength += value.length;
                setProgress(((receivedLength / total) * 100).toFixed(2));
                setDownloadedMB(receivedLength / (1024 * 1024));
            }
        }

        const blob = new Blob(chunks);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = "动态壁纸桌面应用(Windows)";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();

        setDownloadModalVisible(false);
    };


  return (
    <div className="App">
        <Navbar updateSearch={undefined} showSearch={false} fullIcon={Logo}/>

        <Modal
            title="下载中，请稍候"
            open={downloadModalVisible}
            footer={null}
            onCancel={() => null}
        >
            <Progress percent={parseFloat(progress)} />
            <p>已经下载： {downloadedMB.toFixed(2)} MB / {totalMB.toFixed(2)} MB</p>
        </Modal>

        <Flex align={"center"}>
            <img
                style={{borderRadius:"8px",
                    width: '60vw',
                    maxWidth : '900px',
                    maxHeight : '600px',
                    boxShadow: '0 0 4px 10px'
                }}
                alt={"桌面端应用截图"}
                src={desktop_screenshot_path}
                className={"wallpaperDetailDisplay"}
            >

            </img>

            <div style={{border: "2px solid gray",
                borderRadius: "8px",
                width: "100%",
                maxHeight: "500px",
                maxWidth: "400px",
                padding: "10px",
                boxShadow: "3px 3px 10px gray",
                overflowY: "auto",
                fontFamily: "KaiTi"}}
                 className={"wallpaperDetailSection"}>

                <span style={{
                    fontSize: "28px",
                    textShadow: "2px 2px 5px gray",
                    fontWeight:"900"
                }}>
                    <PictureOutlined /> 动态壁纸桌面工具
                </span>
                <br></br>
                <br></br>
                <div style={{
                    maxWidth:"300px",
                    margin:"auto auto",
                }} className={"wallpaperDetail"}>
                    <span>
                        <WindowsOutlined /> 将喜欢的动态壁纸应用到你的桌面！
                    </span>
                    <br></br>
                    <br></br>

                    <span>
                        <FolderOutlined /> 426.25 MB
                    </span>
                    <br></br>
                    <br></br>
                </div>

                <div style={{textAlign:"center"}}>
                    <Button type={"primary"}
                            onClick={onDownload}
                            className={"wallpaperDetailButton"}>
                        <DownloadOutlined /> 下载 Windows版
                    </Button>
                </div>

            </div>
        </Flex>

    </div>
  );
}

export default Page;
