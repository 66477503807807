import React, {useState} from 'react';
import '../../App.css';
import {Button, Flex, Modal, Progress} from "antd";
import Navbar from "../../components/Navbar";
import {
    PictureOutlined, WindowsOutlined, DownloadOutlined, FolderOutlined,
} from "@ant-design/icons";
import Logo from '../../assets/logo.svg';


function isVideo(path: string): boolean {
    const ext = path.split('.').pop();
    return ext === 'mp4';
}
function Page() {
  return (
    <div className="App">
        <Navbar updateSearch={undefined} showSearch={false} fullIcon={Logo}/>

        <div style={{verticalAlign:"center", margin:"10px"}}>
            我是教程内容<br></br>

        </div>

    </div>
  );
}

export default Page;
