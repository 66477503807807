import React, {useEffect, useState} from 'react';
import '../App.css';
import Navbar from './Navbar';
import WallpaperSector from './WallpaperSector';
import {WallpaperEntity} from "../entity/WallpaperEntity";
import {fetchData} from "../apis/api";
import {Button, Collapse, Spin} from "antd";
import {useNavigate} from "react-router-dom";
import BannerWallpaperSector from "./BannerWallpaperSector";
import {WallpaperListType} from "../entity/WallpaperListEntity";
import {getIsAdmin} from "../components/UserInfo";


export type BannerWallpaperType = {
    "seq": number
    "wallpaperId": string
    "wallpaper": WallpaperEntity
}

// 配置是否展示管理员页面以及其入口。对外版应用程序应该配为false
export const showAdmin = false;

function App() {


    const [wallpaperResponse, setWallpaper] = useState<WallpaperListType | null>(null);

    const [bannerWallpaperResponse, setBannerWallpaper] = useState<BannerWallpaperType[] | null>(null);

    const [error, setError] = useState<string | null>(null);
    const [loadingWallpaper, setLoadingWallpaper] = useState<boolean>(true);
    const [loadingBanner, setLoadingBanner] = useState<boolean>(true);

    const [searchTag, setSearchTag] = useState<string>("");
    const [status, setStatus] = useState<number | null>(0);
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();



    // 跳转壁纸详情页
    const jumpDetail = (wallpaperId: number) => {
        navigate('/dialog/'+wallpaperId);
    };
    // 跳转到前台主页
    const jumpHome = () => {
        navigate('/');
    };

    const getWallpaper = async (page: number, searchTag: string, status: number|null) => {
        try {
            setLoadingWallpaper(true);
            if (status === 999){
                // 999:全选
                const wallpaperRequest = await fetchData('/admin/wallpaper/get-by-status?status=&offset=12&page=' + page + "&searchStr=" + encodeURIComponent(searchTag));
                setWallpaper(wallpaperRequest);
            } else {
                const wallpaperRequest = await fetchData('/admin/wallpaper/get-by-status?status='+(status || '')+'&offset=12&page=' + page + "&searchStr=" + encodeURIComponent(searchTag));
                setWallpaper(wallpaperRequest);
            }
        } catch (error) {
            setError("获取壁纸数据失败");
        } finally {
            setLoadingWallpaper(false);
        }
    };

    useEffect(() => {
        // page 或者 searchTag 改变的时候，更新wallpaper数据
        getWallpaper(page, searchTag, status);
        getBannerWallpaper()
    }, [page, searchTag, status]);

    // 暴露给下层组件的刷新（不改变状态）
    const updateResult = () => {
        getWallpaper(page, searchTag, status);
        getBannerWallpaper()
    }

    // 暴露给 WallpaperSector 组件调用的 setPage 函数
    const updatePage = (newPage: number) => {
        setPage(newPage);
    };
    // 暴露给 Navbar 组件调用的 setSearchTag 函数
    const updateSearch = (search: string, status: number|null) => {
        setSearchTag(search);
        setStatus(status);
        setPage(1);
    };

    const getBannerWallpaper = async () => {
        try {
            setLoadingBanner(true)
            const bannerWallpaperRequest = await fetchData('/admin/banner/get');
            setBannerWallpaper(bannerWallpaperRequest);
        } catch (error) {
            setError("获取壁纸数据失败");
        } finally {
            setLoadingBanner(false)
        }
    };

    const updateBannerWallpaper = () => {
        getBannerWallpaper()
        getWallpaper(page, searchTag, status);

    }


    useEffect(() => {
        // 加载页面时检查权限，如果不是管理员，则设置错误，禁止操作
        if (!getIsAdmin()){
            setError("非管理员禁止使用后台")
        }
    }, []);


    if (!showAdmin){
        return <div>！管理员页面不开放！</div>
    }

    if (error) {
        return <div className={"loading"}>
            <div>发生错误：{error}</div>
            <div>
                <Button onClick={() => window.location.reload()}
                        style={{margin:"10px"}}>重新加载</Button>
            </div>

        </div>;
    }
  return (
    <div className="App">
        <Navbar updateSearch={updateSearch} updateResult={updateResult}/>
        <Collapse defaultActiveKey={[1,2]}
            items={[
                { key: '1', label: '精选壁纸', children:
                        (
                            loadingBanner||!bannerWallpaperResponse ? (
                                <div className="loading">
                                    <Spin size="large" />
                                </div>
                            ) : (
                                <div className="content">
                                    <BannerWallpaperSector
                                        update={updateBannerWallpaper}
                                        wallpapers={bannerWallpaperResponse}
                                    />
                                </div>
                            )
                    )
                     },
                { key: '2', label: '所有壁纸', children:
                        (
                            loadingWallpaper||!wallpaperResponse ? (
                                <div className="loading">
                                    <Spin size="large" />
                                </div>
                            ) : (
                                    <div className="content">
                                        <WallpaperSector updatePage={updatePage} updateResult={updateResult} wallpapers={wallpaperResponse}/>
                                    </div>
                                )
                        )
                     }

            ]}
        />
        版本号：1.20<br></br>
    </div>
  );
}

export default App;
