import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    Progress, Radio,
    Row,
    Space,
    Upload,
    UploadFile,
    UploadProps
} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {baseUrl, fetchData, fetchDataSilent, MAX_FILE_SIZE} from "../../apis/api";
import {RcFile} from "antd/es/upload";
import {getUserToken} from "../../components/UserInfo";
import {BlogType} from "./page";

interface DrawerProps {
    open: boolean;
    onClose: () => void;
}
const EditDrawer: React.FC<DrawerProps> = ({ open, onClose }) => {

    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentFile, setCurrentFile] = useState<string | null>(null);

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [form] = Form.useForm();

    const [blogResponse, setBlog] = useState<BlogType|null>(null);


    // 获取博客。
    const getBlog = async (userId:string|undefined) => {
        try {
            const blogRequest = await fetchDataSilent('/blog/view-by-user?userId='+userId);
            setBlog(blogRequest);
            console.log("test:");
            console.log(blogRequest);
        } catch (error) {
            console.error("获取博客数据失败");
        }
    };

    useEffect(() => {
        // const userToken = getUserToken();
        // const userToken = "665eb29256d17e28093e8a82"
        const userToken = "12345"
        if (userToken != null) {
            getBlog(userToken)
        }

    }, []);

    // 新版上传壁纸（带有本地进度条）
    const onCreateSubmit = async () => {
        try {
            const values = await form.validateFields();
            const fileList = values.file.fileList;

            setUploading(true);
            setProgress(0);

            for (const file of fileList) {
                if (file.originFileObj.size >= MAX_FILE_SIZE) {
                    message.error(`文件 "${file.name}" 超过20MB，请压缩或选择更小的文件。`);
                    setUploading(false);
                    setProgress(0);
                    return; // 停止上传过程
                }
                await uploadFile(values, file.originFileObj as RcFile);
            }

            message.success('壁纸上传完成');
            form.resetFields();
            setFileList([]);
            setUploading(false);
            setProgress(100);
            setCurrentFile(null);
        } catch (errorInfo) {
            console.error(errorInfo);
            message.error('请填写所有必填字段！');
            setUploading(false);
        } finally {
            onClose();
        }
    };

    const handleFileChange: UploadProps['onChange'] = (info) => {
        const newFileList = info.fileList.map(file => {
            if (file.response) {
                // 修改 file 状态为 done
                file.status = 'done';
            }
            return file;
        });
        setFileList(newFileList);
    };


    const customRequest = ({ file, onSuccess }: any) => {
        // 使用 setTimeout 模拟文件上传并立即调用 onSuccess
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const uploadFile = (values: any, file: RcFile) => {
        // 由于这个请求需要进度条，所以没有写在api.ts里面
        const authToken = getUserToken();
        if (authToken == null){
            message.warning('请在插件端登录后操作');
            return
        }

        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('title', values.title);
            formData.append('content', values.content);
            formData.append('public', values.public);

            const xhr = new XMLHttpRequest();
            xhr.open('POST', baseUrl+'blog/edit', true);

            // 设置 Authorization 请求头
            xhr.setRequestHeader('Authorization', `${authToken}`);

            xhr.upload.onprogress = (event: ProgressEvent) => {
                if (event.lengthComputable) {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    setProgress(percent);
                    setCurrentFile(file.name);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(xhr.response);
                }
            };

            xhr.onerror = () => {
                reject(xhr.response);
            };

            xhr.send(formData);
        });
    };


    return <Drawer title="博客编辑" onClose={onClose} open={open}
                   extra={
                       <Space>
                           <Button onClick={onClose}>取消</Button>
                           <Button onClick={onCreateSubmit} type="primary" disabled={uploading}>
                               保存
                           </Button>
                       </Space>
                   }>
        <Modal
            visible={uploading}
            footer={null}
            closable={false}
            centered
        >
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <p>{currentFile ? `正在上传: ${currentFile}` : '准备上传...' }</p>
                <Progress percent={progress} status="active" />
                <span style={{color:"#777"}}>
                            提示：上传完成后仍需等待文件从服务器存到云端...
                        </span>
            </div>
        </Modal>

        <Form layout="vertical" form={form}>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name="file" label="编辑博客">
                        <Upload
                            name="wallpaper"
                            accept="image/jpeg,image/jpg,image/png,image/bmp,image/webp,image/jfif"
                            fileList={fileList}
                            onChange={handleFileChange}
                            customRequest={customRequest}
                            multiple={true}
                        >
                            <Button>
                                <UploadOutlined /> 点击上传
                            </Button>
                        </Upload>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="title"
                        label="博客标题"
                        rules={[{ required: true, message: '请输入博客的名称！' }]}
                        initialValue={"美好的壁纸与你分享"}
                    >
                        <Input placeholder="博客的标题"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="content"
                        label="内容"
                        rules={[{ required: true, message: '请输入博文具体内容！' }]}
                        initialValue={"欢迎你来使用我的壁纸"}
                    >
                        <TextArea placeholder="博文详细内容" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="public"
                        label="是否公开"
                        rules={[{ required: true, message: '请选择是否公开' }]}
                        initialValue={1}
                    >

                        <Radio.Group>
                            <Radio value={0}>编辑中</Radio>
                            <Radio value={1}>公开</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Drawer>
}


export default EditDrawer;
