//点击动画特效
var body = document.body;
body.addEventListener("click", function(event) {
    // 创建流光特效
    var glow = document.createElement("div");
    glow.className = "glow";
    glow.style.position = "absolute";
    glow.style.left = event.pageX - 40 + "px";
    glow.style.top = event.pageY - 40 + "px";
    glow.style.zIndex = "1000000";
    body.appendChild(glow);

    // 在动画结束后删除特效元素
    setTimeout(function() {
        glow.parentNode.removeChild(glow);
    }, 900);
});