import React, {useEffect, useState} from 'react';
import '../../App.css';
import Navbar from "../../components/Navbar";
import WallpaperSector from '../../components/WallpaperSector';
import {fetchData} from "../../apis/api";
import {Button, Carousel, Flex, Spin} from "antd";
import {useNavigate} from "react-router-dom";
import CarouselWallpaperObject from "../../components/CarouselWallpaperObject";
import {WallpaperEntity} from "../../entity/WallpaperEntity";
import {WallpaperListType} from "../../entity/WallpaperListEntity";
import Logo from '../../assets/logo.svg';

export type BannerWallpaperType = {
    "wallpaper": WallpaperEntity
}


export const defaultSearchOrder = "创建时间";


function Page() {

    const [wallpaperResponse, setWallpaper] = useState<WallpaperListType | null>(null);

    const [bannerWallpaperResponse, setBannerWallpaper] = useState<BannerWallpaperType[] | null>(null);

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const [searchTag, setSearchTag] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();

    const [searchOrder, setSearchOrder] = useState<string>(defaultSearchOrder);
    const [randomMode, setRandomMode] = useState<boolean>(false);

    const [initializeComplete, setInitializeComplete] = useState<boolean>(false);

    // 跳转壁纸详情页
    const jumpDetail = (wallpaperId: number) => {
        navigate('/dialog/'+wallpaperId);
    };
    // 跳转到管理员页面
    const jumpAdmin = () => {
        navigate('/admin/');
    };

    const getWallpaper = async (page: number, searchTag: string, searchOrder: string) => {
        try {
            setLoading(true);
            // const wallpaperRequest = await fetchData('/wallpaper/search/list?offset=12&page=' + page + "&searchStr=" + encodeURIComponent(searchTag));
            const wallpaperRequest = await fetchData('/wallpaper/search/list-with-order?offset=12&page=' + page + "&searchStr=" + encodeURIComponent(searchTag) + "&orderBy="+encodeURIComponent(searchOrder));

            setWallpaper(wallpaperRequest);
        } catch (error) {
            setError("获取壁纸数据失败");
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        // page（页号） 或者 searchTag（搜索标签） 或者 searchOrder（排序规则） 改变 且 初始化加载完毕 的时候，更新wallpaper数据
        if (initializeComplete){
            getWallpaper(page, searchTag, searchOrder);
        }

    }, [page, searchTag, searchOrder]);

    useEffect(() => {
        // 排序规则改变时，更新是否为随机模式，影响列表是否展示分页。
        if (searchOrder === "随机") {
            setRandomMode(true);
        } else {
            setRandomMode(false);
        }
    }, [searchOrder]);

    // 暴露给 WallpaperSector 组件调用的 setPage 函数
    const updatePage = (newPage: number) => {
        setPage(newPage);

        localStorage.setItem('searchPage', newPage.toString());

    };
    // 暴露给 WallpaperSector 组件调用的 setPage 函数
    const updateResult = () => {
        getWallpaper(page, searchTag, searchOrder);
    };
    // 暴露给 Navbar 组件调用的 setSearchTag 函数
    const updateSearch = (search: string) => {
        setSearchTag(search);
        setPage(1);
        localStorage.setItem('searchPage', "1");
        localStorage.setItem('searchTag', search);
    };
    // 暴露给 Navbar 组件调用的 setSearchOrder 函数
    const updateSearchOrder = (searchOrder: string) => {
        setSearchOrder(searchOrder);
        setPage(1);
        localStorage.setItem('searchPage', "1");
        localStorage.setItem('searchOrder', searchOrder);
    };


    const getBannerWallpaper = async () => {
        try {
            const bannerWallpaperRequest = await fetchData('/admin/banner/get');
            setBannerWallpaper(bannerWallpaperRequest);
        } catch (error) {
            setError("获取精选壁纸数据失败");
        }
    };
    useEffect(() => {
        // 初始加载：获取精选壁纸
        getBannerWallpaper();

        // 初始加载：从缓存获取页号与搜索条件
        let searchPage = localStorage.getItem('searchPage');
        let searchTag = localStorage.getItem('searchTag');
        let searchOrder = localStorage.getItem('searchOrder');

        // 空值判断和类型转换
        let pageNum = searchPage ? parseInt(searchPage, 10) : 1;  // 如果 page 为空，设为 1；否则转为数字
        searchTag = searchTag ? searchTag : '';  // 如果 searchTag 为空，设为空字符串
        searchOrder = searchOrder ? searchOrder : defaultSearchOrder;  // 如果 searchOrder 为空，设为默认排序方式

        setSearchTag(searchTag);
        setPage(pageNum);
        setSearchOrder(searchOrder);

        getWallpaper(pageNum, searchTag, searchOrder);
        setInitializeComplete(true);

    }, []);

    if (error) {
        return <div className={"App"}>
            <Navbar updateSearch={undefined} showSearch={false} fullIcon={Logo}/>
            <div className={"loading"}>

                <div>发生错误：{error}</div>
                <div>建议检查网络环境，刷新重试~</div>
                <div>
                    <Button onClick={() => window.location.reload()}
                            style={{margin:"10px"}}>重新加载</Button>
                </div>

            </div>
        </div>;
    }


    if (!wallpaperResponse || !bannerWallpaperResponse){
        return <div className="App">
            <Navbar updateSearch={updateSearch} showSearch={false} fullIcon={Logo}/>
            <div className="loading">
                <Spin size="large">
                </Spin>
            </div>
        </div>
    }
  return (
    <div className="App">
        <Navbar updateSearch={updateSearch} updateSearchOrder={updateSearchOrder} showSearch={true} fullIcon={Logo}
            initialSearchTag={searchTag} initialSearchOrder={searchOrder}/>
        <Carousel arrows={true}
                  style={{textAlign:"center",
                    backgroundColor:"rgba(77,77,111,0.5)",
                    borderRadius:"12px"}}
                  autoplay={true}
                  autoplaySpeed={5000}
        >
            {bannerWallpaperResponse.map((wallpaper, index) => (
                <CarouselWallpaperObject key={index} wallpaper={wallpaper.wallpaper} />
            ))}

        </Carousel>

        {
            loading &&
            <div className="loading">
                <Spin size="large">
                </Spin>
            </div>
        }

        {
            !loading &&
            <div className="content">
                <WallpaperSector updatePage={updatePage} wallpapers={wallpaperResponse}
                    randomMode={randomMode} updateResult={updateResult}/>
            </div>
        }
    </div>
  );
}

export default Page;
