import React from 'react';
import {useNavigate} from "react-router-dom";
import {WallpaperEntity} from "../entity/WallpaperEntity";

function isVideo(path: string): boolean {
    const ext = path.split('.').pop();
    return ext === 'mp4';
}
function CarouselWallpaperObject({ wallpaper }: { wallpaper: WallpaperEntity }) {


    const navigate = useNavigate();
    const jumpToDetail = () =>{
        navigate('/detail/'+wallpaper.id);
    }

    return (
        <div style={{
            paddingTop:"10px",
            paddingBottom: "15px",
            position:"relative"
        }}>
            {
                // 动态壁纸
                isVideo(wallpaper.path) &&
                <video
                    autoPlay
                    muted
                    loop
                    src={wallpaper.path}
                    style={{
                        borderRadius:"8px",
                        height : '25vw',
                        cursor: "pointer",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                    onClick={jumpToDetail}

                />

            }
            {
                // 静态壁纸
                !isVideo(wallpaper.path) &&

                <img
                    style={{
                        borderRadius:"8px",
                        height : '25vw',
                        cursor: "pointer",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                    alt={wallpaper.name}
                    src={wallpaper.path}
                    onClick={jumpToDetail}
                >

                </img>
            }

            <div
                 style={{
                     background: "rgba(77,77,111,0.2)",
                     color: "#eee",
                     position:"absolute",
                     bottom:"25px",
                     left:"40vw",
                     width: "20vw",
                     textAlign: "center",
                     borderRadius:"6px",
                     zIndex: "10",
                     cursor: "pointer"
                 }}
                onClick={jumpToDetail}>
                <span style={{textShadow:"1px 1px 3px #555", fontWeight:"900"}} className={"bannerTitle"}>{wallpaper.name}</span>
                <br></br>
                <span style={{fontSize:"16px", textShadow:"1px 1px 3px #777"}} className={"bannerDescription"}>{wallpaper.description}</span>
            </div>
        </div>
    )


}

export default CarouselWallpaperObject;