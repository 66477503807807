import React, {useState} from 'react';
import WallpaperObject from "../../components/WallpaperObject";
import {Flex, Pagination, Row, Col} from "antd";
import {WallpaperEntity} from "../../entity/WallpaperEntity";
/**
 * 这里不用components - WallpaperSector 原因（区别）如下
 * 1. 入参不同
 * 2. 不需要分页和回调
 */
interface WallpaperSectorProps {
    wallpapers: WallpaperEntity[]
}
const WallpaperSector: React.FC<WallpaperSectorProps> = ({ wallpapers}) => {
    return (
        <Flex align={"center"} vertical style={{flex:"1"}} className={"wallpaper-sector"}>
            <div style={{ width: "100%" }}>
                {Array.from({ length: Math.ceil(wallpapers.length / 3) }, (_, rowIndex) => (
                    <Row key={rowIndex} gutter={[16, 16]}>
                        {Array.from({ length: 3 }, (_, colIndex) => {
                            const index = rowIndex * 3 + colIndex;
                            return index < wallpapers.length ? (
                                <Col key={colIndex} span={8}>
                                    <WallpaperObject wallpaper={wallpapers[index]} />
                                </Col>
                            ) : null;
                        })}
                    </Row>
                ))}
            </div>
        </Flex>
    );
};
export default WallpaperSector;