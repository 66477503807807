import React, {useEffect, useState} from 'react';
import '../../App.css';
import Navbar from "../../components/Navbar";
import WallpaperSector from '../../components/WallpaperSector';
import {fetchData} from "../../apis/api";
import {Button, Carousel, Flex, Spin} from "antd";
import {useNavigate} from "react-router-dom";
import CarouselWallpaperObject from "../../components/CarouselWallpaperObject";
import {WallpaperEntity} from "../../entity/WallpaperEntity";
import {WallpaperListType} from "../../entity/WallpaperListEntity";
import {getUserToken} from "../../components/UserInfo";
import Logo from "../../assets/logo.svg";

export type BannerWallpaperType = {
    "wallpaper": WallpaperEntity
}
function Page() {

    const [wallpaperResponse, setWallpaper] = useState<WallpaperListType | null>(null);

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const [searchTag, setSearchTag] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();

    // 跳转壁纸详情页
    const jumpDetail = (wallpaperId: number) => {
        navigate('/dialog/'+wallpaperId);
    };
    // 跳转到管理员页面
    const jumpAdmin = () => {
        navigate('/admin/');
    };

    const getWallpaper = async (page: number, searchTag: string) => {
        try {
            setLoading(true);
            const userToken = getUserToken();
            if (userToken == null || userToken == "NotLogin") {
                setError("请登录后使用本功能！");
                return;
            }
            const wallpaperRequest = await fetchData('/wallpaper/search/my-upload?offset=12&page=' + page + "&searchStr=" + encodeURIComponent(searchTag), userToken);
            setWallpaper(wallpaperRequest);
        } catch (error) {
            setError("获取壁纸数据失败");
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        // page 或者 searchTag 改变的时候，更新wallpaper数据
        getWallpaper(page, searchTag);
    }, [page, searchTag]);

    // 暴露给 WallpaperSector 组件调用的 setPage 函数
    const updatePage = (newPage: number) => {
        setPage(newPage);
    };
    // 暴露给 Navbar 组件调用的 setSearchTag 函数
    const updateSearch = (search: string) => {
        setSearchTag(search);
        setPage(1);
    };

    const updateResult = () => {
        getWallpaper(page,searchTag);
    }


    if (error) {
        return <div className={"App"}>
            <Navbar updateSearch={undefined} showSearch={false} fullIcon={Logo}/>
            <div className={"loading"}>

                <div>发生错误：{error}</div>
                <div>建议检查网络环境，刷新重试~</div>
                <div>
                    <Button onClick={() => window.location.reload()}
                            style={{margin:"10px"}}>重新加载</Button>
                </div>

            </div>
        </div>;
    }

    if (!wallpaperResponse){
        return <div className="App">
            <Navbar updateSearch={updateSearch} showSearch={false}/>
            <div className="loading">
                <Spin size="large">
                </Spin>
            </div>
        </div>
    }
  return (
    <div className="App">
        <Navbar updateSearch={updateSearch} updateResult={updateResult} showSearch={true} showUpload={true}/>

        {
            loading &&
            <div className="loading">
                <Spin size="large">
                </Spin>
            </div>
        }

        {
            !loading &&
            <div className="content">
                <WallpaperSector updatePage={updatePage} updateResult={updateResult}
                                 wallpapers={wallpaperResponse} showEdit={true} showDelete={true} showApply={false} showDownload={false}/>
            </div>
        }

    </div>
  );
}

export default Page;
