import React, {useEffect, useState} from 'react';
import '../../App.css';
import WallpaperSector from './WallpaperSector';
import {fetchData, installUrl, postDataUser, postDataUserSilent} from "../../apis/api";
import {Button, Flex, Input, message, Modal, Progress, Rate, Select, Spin} from "antd";
import {useParams} from "react-router-dom";
import Navbar from "../../components/Navbar";
import {
    PictureOutlined, TagsOutlined, HeartOutlined, StarOutlined,
    DeploymentUnitOutlined, PlusOutlined, ShareAltOutlined, DownloadOutlined,
    BarsOutlined, FullscreenOutlined, FolderOutlined,
    HeartFilled, StarFilled, UserOutlined ,
    WechatWorkOutlined
} from "@ant-design/icons";
import {getUserToken} from "../../components/UserInfo";
import {WallpaperEntity} from "../../entity/WallpaperEntity";
import Logo from '../../assets/logo.svg';
import TextArea from "antd/es/input/TextArea";


function isVideo(path: string): boolean {
    const ext = path.split('.').pop();
    return ext === 'mp4';
}
function Page() {

    const { wallpaperId } = useParams<{ wallpaperId: string }>();

    const [wallpaperResponse, setWallpaper] = useState<WallpaperEntity[] | null>(null);
    const [wallpaper, setThisWallpaper] = useState<WallpaperEntity | null>(null);

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const getThisWallpaper = async (wallpaperId:string|undefined) =>{
        if (wallpaperId === undefined){
            return;
        }
        try {
            setLoading(true);
            const userToken = getUserToken();
            const wallpaperRequest = await fetchData('/wallpaper/search/get-by-id?id=' + encodeURIComponent(wallpaperId), userToken);
            setThisWallpaper(wallpaperRequest);
        } catch (error) {
            setError("获取壁纸数据失败");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        // wallpaperId 改变的时候，更新wallpaper数据
        getThisWallpaper(wallpaperId);
        getWallpaper(wallpaperId);
    }, [wallpaperId]);


    const getWallpaper = async (wallpaperId:string|undefined) => {
        try {
            const wallpaperRequest = await fetchData('/wallpaper/search/get-similar?id='+wallpaperId);
            setWallpaper(wallpaperRequest);
        } catch (error) {
            setError("获取壁纸数据失败");
        }
    };

    // 应用（未安装插件时，直接跳转应用商店）
    const handleApply = () => {
        const userToken = getUserToken();
        if (userToken == null) {
            window.open(installUrl, '_blank');
        } else {
            // 只要安装了应用，就给本壁纸+1分
            postDataUserSilent("/wallpaper/interact/heat-up", {wallpaperId: wallpaper?.id, heat: 1});
        }
    }
    // 喜欢壁纸
    const handleLike = async () => {
        try {
            const userToken = getUserToken();
            if (userToken == null || userToken == "NotLogin") {
                message.warning('请在插件端登录后操作');
            }
            if (wallpaper == null || userToken == null || userToken == "NotLogin") {
                return
            }
            await postDataUser("/wallpaper/interact/like", {wallpaperId: wallpaper.id}, userToken);
            getThisWallpaper(wallpaperId);
        } catch (error) {
            setError("壁纸互动失败")
        }
    }
    // 撤销喜欢壁纸
    const handleUndoLike = async () => {
        try {
            const userToken = getUserToken();
            if (userToken == null || userToken == "NotLogin") {
                message.warning('请在插件端登录后操作');
            }
            if (wallpaper == null || userToken == null || userToken == "NotLogin") {
                return
            }
            await postDataUser("/wallpaper/interact/undo-like", {wallpaperId: wallpaper.id}, userToken);
            getThisWallpaper(wallpaperId);
        } catch (error) {
            setError("壁纸互动失败")
        }
    }
    // 收藏壁纸
    const handleSubscribe = async () => {
        try {
            const userToken = getUserToken();
            if (userToken == null || userToken == "NotLogin"){
                message.warning('请在插件端登录后操作');
            }
            if (wallpaper == null || userToken == null || userToken == "NotLogin"){
                return
            }
            await postDataUser("/wallpaper/interact/subscribe",{wallpaperId : wallpaper.id},userToken);
            getThisWallpaper(wallpaperId);

        } catch (error) {
            setError("壁纸互动失败")
        }
    }
    // 撤销收藏壁纸
    const handleUndoSubscribe = async () => {
        try {
            const userToken = getUserToken();
            if (userToken == null || userToken == "NotLogin") {
                message.warning('请在插件端登录后操作');
            }
            if (wallpaper == null || userToken == null || userToken == "NotLogin") {
                return
            }
            await postDataUser("/wallpaper/interact/undo-subscribe", {wallpaperId: wallpaper.id}, userToken);
            getThisWallpaper(wallpaperId);

        } catch (error) {
            setError("壁纸互动失败")
        }
    }
    // 评分
    const handleRating = async (rating:number) => {
        try {
            const userToken = getUserToken();
            if (userToken == null || userToken == "NotLogin") {
                message.warning('请在插件端登录后操作');
            }
            if (wallpaper == null || userToken == null || userToken == "NotLogin") {
                return
            }
            await postDataUser("/wallpaper/interact/rate", {wallpaperId: wallpaper.id, rating:rating}, userToken);
            getThisWallpaper(wallpaperId);

        } catch (error) {
            setError("壁纸评分失败")
        }
    }

    const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
    const [feedbackType, setFeedbackType] = useState<string>('');
    const [feedbackMessage, setFeedbackMessage] = useState<string>('');
    // 打开反馈面板
    const handleFeedback =  () => {
        const userToken = getUserToken();
        if (userToken == null || userToken == "NotLogin") {
            message.warning('请在插件端登录后操作');
        }
        if (wallpaper == null || userToken == null || userToken == "NotLogin") {
            return
        }
        setFeedbackModalVisible(true);

    }

    const handleFeedbackCancel = () => {
        setFeedbackModalVisible(false);
    };

    const handleFeedbackSend = async () => {
        // console.log('Feedback Type:', feedbackType);
        // console.log('Feedback Message:', feedbackMessage);
        if (feedbackType == null || feedbackType == "") {
            message.warning("请选择反馈类型");
            return;
        }
        if (feedbackMessage == null || feedbackMessage == "") {
            message.warning("请填写反馈内容");
            return;
        }
        try {
            const userToken = getUserToken();
            if (userToken == null || userToken == "NotLogin") {
                message.warning('请在插件端登录后操作');
            }
            if (wallpaper == null || userToken == null || userToken == "NotLogin") {
                return
            }
            await postDataUser("/wallpaper/interact/feedback",
                {wallpaperId: wallpaper.id, type: feedbackType, message:feedbackMessage}, userToken);
            getThisWallpaper(wallpaperId);

        } catch (error) {
            setError("壁纸互动失败")
        } finally {
            setFeedbackModalVisible(false);
        }
    };

    const handleFeedbackTypeChange = (value: string) => {
        setFeedbackType(value);
    };

    const handleFeedbackMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFeedbackMessage(e.target.value);
    };


    const [shareModalVisible, setShareModalVisible] = useState(false);
    const [shareContent, setShareContent] = useState("");
    // 分享壁纸
    const handleShare = async () => {
        try {
            const userToken = getUserToken();
            // if (userToken == null || userToken == "NotLogin") {
            //     message.warning('请在插件端登录后操作');
            // }
            if (wallpaper == null) {
                return
            }
            const shareResponse = await postDataUser("/wallpaper/interact/share2", {wallpaperId: wallpaper.id}, userToken);
            // setShareContent(shareResponse);
            // 改版：改为分享当前页面的地址。
            const currentURL = window.location.href;
            setShareContent(currentURL);
            setShareModalVisible(true);

        } catch (error) {
            setError("壁纸互动失败")
        }
    }
    async function handleShareCopy(): Promise<void> {
        try {
            if (navigator.clipboard && window.isSecureContext) {
                // 如果浏览器支持 Clipboard API 且在安全上下文中
                await navigator.clipboard.writeText(shareContent);
            } else {
                // 备用方法
                await new Promise<void>((resolve, reject) => {
                    let textArea = document.createElement("textarea");
                    textArea.value = shareContent;
                    textArea.style.position = "fixed";
                    textArea.style.left = "-999999px";
                    textArea.style.top = "-999999px";
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();

                    if (document.execCommand('copy')) {
                        resolve();
                    } else {
                        reject(new Error('Failed to copy'));
                    }
                    textArea.remove();
                });
            }
            message.success('内容已复制到剪切板');
        } catch (error) {
            console.error('复制失败:', error);
            message.error('复制失败');
        } finally {
            setShareModalVisible(false);
        }
    }
    const handleShareCancel = () => {
        setShareModalVisible(false);
    }

    const [progress, setProgress] = useState("0");
    const [downloadModalVisible, setDownloadModalVisible] = useState(false);
    const [downloadedMB, setDownloadedMB] = useState(0);
    const [totalMB, setTotalMB] = useState(0);

    const onDownload = async () => {
        if (wallpaper == null){
            return
        }

        setDownloadModalVisible(true);

        const response = await fetch(wallpaper.path);
        const contentLength = response.headers.get('content-length');
        const total = parseInt(contentLength || '0', 10);
        setTotalMB(total / (1024 * 1024)); // 将总字节数转换为MB

        if (!response.body) {
            throw new Error('ReadableStream is not supported in this browser.');
        }

        const reader = response.body.getReader();
        let receivedLength = 0;
        const chunks: Uint8Array[] = [];

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            if (value) {
                chunks.push(value);
                receivedLength += value.length;
                setProgress(((receivedLength / total) * 100).toFixed(2));
                setDownloadedMB(receivedLength / (1024 * 1024));
            }
        }

        const blob = new Blob(chunks);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = isVideo(wallpaper.path) ? wallpaper.name + '.mp4' : wallpaper.name + '.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();

        setDownloadModalVisible(false);

        // 下载就给本壁纸+2分
        postDataUserSilent("/wallpaper/interact/heat-up", {wallpaperId: wallpaper?.id, heat: 2});
    };


    if (error) {
        return <div className={"App"}>
            <Navbar updateSearch={undefined} showSearch={false} fullIcon={Logo}/>
            <div className={"loading"}>

                <div>发生错误：{error}</div>
                <div>建议检查网络环境，刷新重试~</div>
                <div>
                    <Button onClick={() => window.location.reload()}
                            style={{margin:"10px"}}>重新加载</Button>
                </div>

            </div>
        </div>;
    }

    if (!wallpaper || !wallpaperResponse || loading){
        return <div className="App">
            <Navbar updateSearch={undefined} showSearch={false} fullIcon={Logo}/>
            <div className="loading">
                <Spin size="large">
                </Spin>
            </div>
        </div>
    }
  return (
    <div className="App">
        <Navbar updateSearch={undefined} showSearch={false} fullIcon={Logo}/>

        <Modal
            title="下载中，请稍候"
            open={downloadModalVisible}
            footer={null}
            onCancel={() => null}
        >
            <Progress percent={parseFloat(progress)} />
            <p>已经下载： {downloadedMB.toFixed(2)} MB / {totalMB.toFixed(2)} MB</p>
        </Modal>

        <Modal
            open={shareModalVisible}
            title="分享链接"
            onOk={handleShareCopy}
            onCancel={handleShareCancel}
            footer={[
                <Button key="back" onClick={handleShareCancel}>
                    返回
                </Button>,
                <Button type="primary" onClick={handleShareCopy}>
                    复制
                </Button>,
            ]}
        >
            <p>复制下方内容将本壁纸分享给你的朋友：</p>
            <p>{shareContent}</p>
        </Modal>

        <Modal
            open={feedbackModalVisible}
            title="我要反馈"
            onOk={handleFeedbackSend}
            onCancel={handleFeedbackCancel}
            footer={[
                <Button key="back" onClick={handleFeedbackCancel}>
                    返回
                </Button>,
                <Button type="primary" onClick={handleFeedbackSend}>
                    提交
                </Button>,
            ]}
        >
            <p>欢迎你为壁纸提供反馈和建议！</p>

            <Select
                style={{ marginBottom:"10px" }}
                placeholder="请选择反馈类型"
                onChange={handleFeedbackTypeChange}
                options={[
                    { value: '描述补充', label: '描述补充' },
                    { value: '尺寸不合适', label: '尺寸不合适' },
                    { value: '内容不合适', label: '内容不合适' },
                    { value: '其他', label: '其他'},
                ]}
            />

            <TextArea placeholder="请提供具体反馈内容"
                      onChange={handleFeedbackMessageChange}
                      rows={4}/>

        </Modal>

        <Flex align={"center"}>
            {
                // 动态壁纸
                isVideo(wallpaper.path) &&
                <video
                    autoPlay
                    muted
                    loop
                    src={wallpaper.path}
                    style={{borderRadius:"8px",
                        width: '60vw',
                        maxWidth : '900px',
                        maxHeight : '600px',
                        boxShadow: '0 0 4px 10px'
                    }}
                    className={"wallpaperDetailDisplay"}

                />

            }
            {
                // 静态壁纸
                !isVideo(wallpaper.path) &&

                <img
                    style={{borderRadius:"8px",
                        width: '60vw',
                        maxWidth : '900px',
                        maxHeight : '600px',
                        boxShadow: '0 0 4px 10px'
                    }}
                    alt={wallpaper.name}
                    src={wallpaper.path}
                    className={"wallpaperDetailDisplay"}
                >

                </img>
            }

            <div style={{border: "2px solid gray",
                borderRadius: "8px",
                width: "100%",
                maxHeight: "500px",
                maxWidth: "400px",
                padding: "10px",
                boxShadow: "3px 3px 10px gray",
                overflowY: "auto",
                fontFamily: "KaiTi"}}
                 className={"wallpaperDetailSection"}>

                <span style={{
                    fontSize: "28px",
                    textShadow: "2px 2px 5px gray",
                    fontWeight:"900"
                }}>
                    <PictureOutlined /> {wallpaper.name}
                    {/*<Rate style={{margin:"5px"}}*/}
                    {/* onChange={handleRating}*/}
                    {/* defaultValue={wallpaper.userRating}/>*/}
                </span>
                {/*<span style={{*/}
                {/*    margin: "5px"*/}
                {/*}}>*/}
                {/*    ({wallpaper.averageRating}<StarOutlined />)*/}
                {/*</span>*/}
                <br></br>
                <br></br>
                <div style={{
                    maxWidth:"300px",
                    margin:"auto auto",
                }} className={"wallpaperDetail"}>
                    <span>
                        <BarsOutlined /> {wallpaper.description}
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                        <TagsOutlined /> {wallpaper.tag}
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                        <FolderOutlined /> {wallpaper.fileSize}
                    </span>
                    <br></br>
                    <br></br>
                    {
                        wallpaper.width && wallpaper.height &&
                        <span><FullscreenOutlined /> {wallpaper.width} * {wallpaper.height}
                            <br></br>
                            <br></br>
                        </span>

                    }
                    {/*<span>*/}
                    {/*    <HeartOutlined />： {wallpaper.likes}   <StarOutlined />： {wallpaper.subscribes}*/}
                    {/*</span>*/}

                    <br></br>
                    <br></br>

                    {
                        wallpaper.createBy &&
                        <span>
                            <UserOutlined />： 由 {wallpaper.createBy.nickname} 上传
                        </span>
                    }

                    <br></br>
                </div>

                <div style={{textAlign:"center"}}>
                    <Button onClick={handleApply}
                        type={"primary"}
                        className={"wallpaperDetailButton wallpaperApplyButton"}
                        data-id={wallpaper.id}>
                        <PlusOutlined /> 应用
                    </Button>

                    {/*{*/}
                    {/*    !wallpaper.userHasLiked &&*/}
                    {/*    <Button onClick={handleLike}*/}
                    {/*            className={"wallpaperDetailButton"}*/}
                    {/*    >*/}
                    {/*        <HeartOutlined /> 喜欢*/}
                    {/*    </Button>*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    wallpaper.userHasLiked &&*/}
                    {/*    <Button onClick={handleUndoLike}*/}
                    {/*            className={"wallpaperDetailButton"}*/}
                    {/*    >*/}
                    {/*        <HeartFilled /> 已喜欢*/}
                    {/*    </Button>*/}
                    {/*}*/}


                    {/*{*/}
                    {/*    !wallpaper.userHasSubscribed &&*/}
                    {/*    <Button onClick={handleSubscribe}*/}
                    {/*            className={"wallpaperDetailButton"}>*/}
                    {/*        <StarOutlined /> 收藏*/}
                    {/*    </Button>*/}

                    {/*}*/}

                    {/*{*/}
                    {/*    wallpaper.userHasSubscribed &&*/}
                    {/*    <Button onClick={handleUndoSubscribe}*/}
                    {/*            className={"wallpaperDetailButton"}>*/}
                    {/*        <StarFilled /> 已收藏*/}
                    {/*    </Button>*/}

                    {/*}*/}

                    {/*<Button onClick={handleFeedback}*/}
                    {/*        className={"wallpaperDetailButton"}*/}
                    {/*>*/}
                    {/*    <WechatWorkOutlined /> 反馈*/}
                    {/*</Button>*/}

                    <Button onClick={handleShare}
                            className={"wallpaperDetailButton"}>
                        <ShareAltOutlined /> 分享
                    </Button>
                    <Button onClick={onDownload}
                            className={"wallpaperDetailButton"}>
                        <DownloadOutlined /> 下载
                    </Button>
                </div>

            </div>
        </Flex>

        <div style={{maxWidth:"1000px",
            margin: "auto auto"}}>

            <span
                style={{
                    fontSize:"28px",
                    textAlign:"left",
                    display:"inline-block",
                    width:"100%",
                    marginTop:"10px",
                    marginBottom:"10px"
                }}>
                <DeploymentUnitOutlined /> 同类壁纸
            </span>

        </div>
        <div className="content" style={{marginBottom:"30px"}}>
            <WallpaperSector wallpapers={wallpaperResponse}/>
        </div>
    </div>
  );
}

export default Page;
