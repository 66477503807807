import React, { useState} from 'react';
import {Button, Col, Drawer, Flex, Form, Image, Input, message, Modal, Progress, Row, Space} from 'antd';
import {
    DeleteOutlined,
    DownloadOutlined, EditOutlined, ExclamationCircleFilled,
    PlusOutlined, StarFilled, StarOutlined
} from '@ant-design/icons';
import VideoWallpaperTag from '../assets/video-wallpaper.svg';
import {useNavigate} from "react-router-dom";
import {installUrl, postDataUser, postDataUserSilent} from "../apis/api";
import {WallpaperEntity} from "../entity/WallpaperEntity";
import {getUserToken} from "./UserInfo";
import TextArea from "antd/es/input/TextArea";
import wallpaperObject from "../admin/WallpaperObject";


interface WallpaperObjectProps {
    wallpaper: WallpaperEntity;
    updateResult?: () => void;
    showApply?: boolean;
    showDownload?: boolean;
    showSubscribe?: boolean;
    showEdit?: boolean;
    showDelete?: boolean;
}
function isVideo(wallpaper:WallpaperEntity): boolean {
    return wallpaper.type === '动态';
}
const WallpaperObject: React.FC<WallpaperObjectProps> = ({ wallpaper, updateResult,
                                                         showApply = true, showDownload = true,
                                                         showSubscribe = false, showEdit = false, showDelete = false }) => {


    const navigate = useNavigate();
    const [progress, setProgress] = useState("0");
    const [modalVisible, setModalVisible] = useState(false);
    const [downloadedMB, setDownloadedMB] = useState(0);
    const [totalMB, setTotalMB] = useState(0);


    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };


    const onDownload = async () => {
        setModalVisible(true);

        const response = await fetch(wallpaper.path);
        const contentLength = response.headers.get('content-length');
        const total = parseInt(contentLength || '0', 10);
        setTotalMB(total / (1024 * 1024)); // 将总字节数转换为MB

        if (!response.body) {
            throw new Error('ReadableStream is not supported in this browser.');
        }

        const reader = response.body.getReader();
        let receivedLength = 0;
        const chunks: Uint8Array[] = [];

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            if (value) {
                chunks.push(value);
                receivedLength += value.length;
                setProgress(((receivedLength / total) * 100).toFixed(2));
                setDownloadedMB(receivedLength / (1024 * 1024));
            }
        }

        const blob = new Blob(chunks);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = isVideo(wallpaper) ? wallpaper.name + '.mp4' : wallpaper.name + '.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();

        setModalVisible(false);
        // 下载就给本壁纸+2分
        postDataUserSilent("/wallpaper/interact/heat-up", {wallpaperId: wallpaper?.id, heat: 2});
    };


    const onApplyWallpaper = () => {
        // 应用壁纸（未安装插件时） 跳转 商店页面
        const userToken = getUserToken();
        if (userToken == null) {
            window.open(installUrl, '_blank');
        } else {
            // 只要安装了应用，就给本壁纸+1分
            postDataUserSilent("/wallpaper/interact/heat-up", {wallpaperId: wallpaper?.id, heat: 1});
        }
    }

    const jumpToDetail = () =>{
        navigate('/detail/'+wallpaper.id);
    }
    // 收藏壁纸
    const handleSubscribe = async () => {
        if (updateResult == null) {
            return
        }
        const userToken = getUserToken();
        if (userToken == null || userToken == "NotLogin"){
            message.warning('请在插件端登录后操作');
        }
        if (wallpaper == null || userToken == null || userToken == "NotLogin"){
            return
        }
        await postDataUser("/wallpaper/interact/subscribe",{wallpaperId : wallpaper.id},userToken);
        updateResult();
    }
    // 撤销收藏壁纸
    const handleUndoSubscribe = async () => {
        if (updateResult == null) {
            return
        }
        const userToken = getUserToken();
        if (userToken == null || userToken == "NotLogin") {
            message.warning('请在插件端登录后操作');
        }
        if (wallpaper == null || userToken == null || userToken == "NotLogin") {
            return
        }
        await postDataUser("/wallpaper/interact/undo-subscribe", {wallpaperId: wallpaper.id}, userToken);
        updateResult();

    }


    const { confirm } = Modal;

    const showConfirm = () => {
        const userToken = getUserToken();
        if (userToken == null || userToken == "NotLogin") {
            message.warning('请在插件端登录后操作');
            return
        }
        confirm({
            title: '你确定要删除这张壁纸吗？',
            icon: <ExclamationCircleFilled />,
            cancelText: "取消",
            okText: "删除",
            okType: "danger",
            content: '你确定删除本壁纸吗？',
            onOk() {
                onDeleteWallpaper();
            },
            onCancel() {
            },
        });
    };
    const onDeleteWallpaper = async () => {
        if (updateResult == null){
            return;
        }
        const userToken = getUserToken();
        if (userToken == null || userToken == "NotLogin") {
            message.warning('请在插件端登录后操作');
            return
        }
        // 删除壁纸，确认后执行删除
        try {
            await postDataUser('/wallpaper/delete', {wallpaperId : wallpaper.id}, userToken);
        } catch (error) {
            console.error(error);
        } finally {
            updateResult();
        }
    }

    const onEditWallpaper = () => {
        // 编辑壁纸 弹出编辑面板
        showDrawer();
    }
    const [form] = Form.useForm();
    const onEditSubmit = async () => {
        if (updateResult == null) {
            return
        }
        const userToken = getUserToken();
        if (userToken == null || userToken == "NotLogin") {
            message.warning('请在插件端登录后操作');
            return
        }
        try {
            // 进行表单字段的验证
            const values = await form.validateFields();
            // 如果验证通过，调用传入的 onEditSubmit 方法并传递表单数据

            // 调用管理员-更新接口
            await postDataUser('/admin/wallpaper/edit',
                {
                    id : wallpaper.id,
                    name : values.name,
                    description : values.description,
                    tag : values.tag,

                }, userToken);

        } catch (errorInfo) {
            console.error(errorInfo);
            message.error('请填写所有必填字段！');
        } finally {
            updateResult();
        }
    };

    return (
        <div className="wallpaper-preview" style={{margin:"8px"}}>

            <Modal
                title="下载中，请稍候"
                visible={modalVisible}
                footer={null}
                onCancel={() => null}
            >
                <Progress percent={parseFloat(progress)} />
                <p>已经下载： {downloadedMB.toFixed(2)} MB / {totalMB.toFixed(2)} MB</p>
            </Modal>

            {isVideo(wallpaper) &&
                <Image
                    onClick={jumpToDetail}
                    src={wallpaper.previewPath.replace("{width}","600").replace("{height}","400")}
                    className={"image-preview"}
                    style={{borderRadius:"8px",
                        width: '30vw',
                        height: '20vw',
                        maxWidth : '300px',
                        maxHeight : '200px'
                    }}
                    alt={wallpaper.name}
                />
            }
            {!isVideo(wallpaper) &&
                <Image

                    className={"image-preview"}
                    style={{borderRadius:"8px",
                        width: '30vw',
                        height: '20vw',
                        maxWidth : '300px',
                        maxHeight : '200px'
                    }}
                    alt={wallpaper.name}
                    src={wallpaper.previewPath.replace("{width}","600").replace("{height}","400")}
                    onClick={jumpToDetail}
                />
            }

            <Flex vertical={false}
                  style={{position:"absolute",
                      top:"5px",
                      right:"5px",
                      width:"100%",
                      flexDirection:"row-reverse"}}
                  className={"wallpaperButtonDiv"}
            >
                {
                    showApply &&
                    <button
                        style={{
                            background: "rgba(0, 0, 0, 0.5)",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                        className={"wallpaperOpButton wallpaperApplyButton"}
                        onClick={onApplyWallpaper}
                        data-id={wallpaper.id}
                    >

                        <PlusOutlined />
                        应用
                    </button>
                }
                {
                    showDownload &&
                    <button
                        style={{
                            background: "rgba(0, 0, 0, 0.5)",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                        className={"wallpaperOpButton"}
                        onClick={onDownload}
                    >

                        <DownloadOutlined />
                        下载
                    </button>
                }
                {
                    showSubscribe && !wallpaper.userHasSubscribed &&
                    <button
                            style={{
                            background: "rgba(0, 0, 0, 0.5)",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                        className={"wallpaperOpButton"}
                        onClick={handleSubscribe}
                    >

                        <StarOutlined />
                        收藏
                    </button>
                }
                {
                    showSubscribe && wallpaper.userHasSubscribed &&
                    <button
                        style={{
                            background: "rgba(0, 0, 0, 0.5)",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                        className={"wallpaperOpButton"}
                        onClick={handleUndoSubscribe}
                    >

                        <StarFilled />
                        取消收藏
                    </button>
                }
                {
                    showEdit &&
                    <button
                        style={{
                            background: "rgba(0, 0, 0, 0.5)",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                        className={"wallpaperOpButton wallpaperApplyButton"}
                        onClick={onEditWallpaper}
                        data-id={wallpaper.id}
                    >

                        <EditOutlined />
                        编辑
                    </button>
                }
                {
                    showDelete &&
                    <button
                        style={{
                            background: "rgba(0, 0, 0, 0.5)",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                        className={"wallpaperOpButton wallpaperOpButtonAlert"}
                        onClick={showConfirm}
                    >

                        <DeleteOutlined />
                        删除
                    </button>
                }


            </Flex>
            <div className={"wallpaperNameBanner"}
                 style={{
                     background: "rgba(0, 0, 0, 0.5)",
                     color: "#fff",
                     position:"absolute",
                     width:"30vw",
                     maxWidth:"300px",
                     bottom:"0",
                     textAlign: "center",
                     borderRadius:"0 0 6px 6px"
                 }}>
                {wallpaper.name}
            </div>

            {isVideo(wallpaper) &&
                <img
                    src={VideoWallpaperTag}
                    alt="动态视频"
                    style={{
                        position:"absolute",
                        top: "0",
                        left: "-1px",
                        maxWidth:"80px",
                        maxHeight:"60px",
                        width:"4vw",
                        height:"3vw",
                        zIndex:"1",
                    }}
                />
            }

            {
                showEdit &&
                <Drawer title="壁纸编辑" onClose={onClose} open={open}
                        extra={
                            <Space>
                                <Button onClick={onClose}>取消</Button>
                                <Button onClick={onEditSubmit} type="primary">
                                    保存
                                </Button>
                            </Space>
                        }>
                    <Form layout="vertical" form={form}
                          initialValues={{
                              name: wallpaper.name,
                              tag: wallpaper.tag,
                              description: wallpaper.description
                          }}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label="壁纸名称"
                                    rules={[{ required: true, message: '请输入壁纸的名称！' }]}
                                >
                                    <Input placeholder="壁纸的标题"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="tag"
                                    label="标签"
                                    rules={[{ required: true, message: '请输入壁纸的标签！' }]}
                                >
                                    <Input placeholder="壁纸的标签，逗号分割" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label="描述"
                                    rules={[{ required: true, message: '请输入壁纸的描述！' }]}
                                >
                                    <TextArea placeholder="壁纸详细描述" />
                                </Form.Item>
                            </Col>
                        </Row>


                        <div style={{overflowWrap:"anywhere"}}>
                            其他信息:
                            <br></br>
                            总分数：{wallpaper.score}
                            <br></br>
                            互动分数：{wallpaper.interactScore}
                            <br></br>
                            喜欢：{wallpaper.likes}，收藏：{wallpaper.subscribes}，分享：{wallpaper.shares}
                            <br></br>
                            <span style={{color:"#FF2222"}}>不喜欢：</span>{wallpaper.dislikes}
                        </div>
                    </Form>
                </Drawer>
            }
        </div>
    )

}

export default WallpaperObject;
