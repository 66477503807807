import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './glowAnimate.js';
import HomePage from './pages/home/page';
import reportWebVitals from './reportWebVitals';
import AppForAdmin from "./admin/AppForAdmin";
import DetailPage from "./pages/detail/page";
import BlogPage from "./pages/blog/page";
import SubscribePage from "./pages/subscribe/page";
import UploadPage from "./pages/upload/page";
import DesktopPage from "./pages/desktop/page";
import TutorialPage from "./pages/desktop/tutorial";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Router>
          <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/admin/" element={<AppForAdmin />} />
              <Route path="/detail/:wallpaperId" element={<DetailPage />} />
              <Route path="/blog/:userId" element={<BlogPage />} />
              <Route path="/subscribe/" element={<SubscribePage />} />
              <Route path="/upload/" element={<UploadPage />} />
              <Route path="/desktop/" element={<DesktopPage />} />
              <Route path="/tutorial/" element={<TutorialPage />} />
          </Routes>
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
